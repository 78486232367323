import React from "react"
import { Icon } from "semantic-ui-react"
import { Link } from "gatsby"

const Featureds = ({ data }) => {
  const featureds = data.edges
  return (
    <div className="container">
      <div className="row" style={{ marginTop: 40 }}>
        {featureds.map(({ node: feat }) => (
          <div key={feat.titol} className="column col3">
            <div className="feature">
              <div className="feature-image primary-color">
                <div className="feature-icon">
                  <Icon name={feat.icona} />
                </div>
              </div>
              <h3 className="feature-title">{feat.titol}</h3>
              <div className="feature-content">
                <p>{feat.text.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Featureds
